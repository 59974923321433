import { DATE_NUMBER_SLASH } from '@/libs/filterDate'
import {
  firstDateOfMonth, today, last7, last2,
} from '@/store/helpers'

// eslint-disable-next-line import/prefer-default-export
export const tableTeamLead = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'created_at',
    label: 'Tanggal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      width: '80px',
    },
    tdClass: 'text-black',
    class: 'p-1',
    formatter: value => DATE_NUMBER_SLASH(value),
  },
  {
    key: 'team_lead_name',
    label: 'Nama Leader',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'partner_name',
    label: 'nama partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'rating',
    label: 'rating',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'evaluation',
    label: 'evaluasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
]

export const tableTalent = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'created_at',
    label: 'Tanggal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      width: '80px',
    },
    tdClass: 'text-black',
    class: 'p-1',
    formatter: value => DATE_NUMBER_SLASH(value),
  },
  {
    key: 'talent_name',
    label: 'Nama Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'partner_name',
    label: 'nama partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'rating',
    label: 'rating',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'evaluation',
    label: 'evaluasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
]

export const dateRange = {
  startDate: firstDateOfMonth,
  endDate: today,
}

export const locale = {
  format: 'dd/mm/yyyy',
  daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
}

export const ranges = {
  'Hari ini': [today, today],
  '2 Hari Terakhir': [last2, today],
  '7 Hari Terakhir': [last7, today],
  'Bulan Ini': [firstDateOfMonth, today],
}
