<template>
  <div>
    <div class="text-black">
      <BCol
        md="12"
        class="d-flex gap-[10px] w-100 p-0 my-[24px]"
      >
        <div class="d-flex rounded-lg align-items-center border search-bar">
          <span
            class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
          />
          <BFormInput
            v-model="keyword"
            placeholder="Cari Nama"
            class="border-0"
            @input="handleSearch()"
          />
        </div>
        <div class="w-[250px] container-date">
          <date-range-picker
            ref="picker"
            v-model="dateRange"
            :locale-data="locale"
            :ranges="ranges"
            :opens="'center'"
            @update="getListData()"
          >
            <!-- eslint-disable -->
            <template
              v-slot:input="dateRange"
              style="height: 100%"
            >
              <div class="d-flex justify-content-between align-items-center w-100 h-100">
                <div class="mr-1">
                  <span
                    v-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(today)"
                    style="color: #828282 !important"
                  >
                    Hari ini
                  </span>
                  <span
                    v-else-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(last2)"
                    style="color: #828282 !important"
                  >
                    2 Hari Terakhir
                  </span>
                  <span
                    v-else-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(last7)"
                    style="color: #828282 !important"
                  >
                    7 Hari Terakhir
                  </span>
                  <span
                    v-else-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(firstDateOfMonth) && DDMMYYYY(dateRange.endDate) === DDMMYYYY(today)"
                    style="color: #828282 !important"
                  >
                    Bulan ini
                  </span>
                  <span
                    v-else
                    style="color: #828282 !important"
                  >
                    {{`${dateRange.startDate ? DDMMYYYY(dateRange.startDate) : 'Pilih tanggal'} - ${dateRange.endDate ? DDMMYYYY(dateRange.endDate) : 'Pilih tanggal'}`}}
                  </span>
                </div>
                <div>
                  <span class="k-calendar-2" />
                </div>
              </div>
            </template>
          </date-range-picker>
        </div>
      </BCol>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 340px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            <div class="flex items-center">
              <div class="ml-1">
                {{ data.item.name }}
              </div>
            </div>
          </template>
          <template #cell(rating)="data">
            <div class="d-flex items-start">
              {{ data.item.rating }}
              <feather-icon icon="StarIcon" class="ml-50 text-warning filled" />
            </div>
          </template>
          <template #cell(evaluation)="data">
            <div class="evaluation" :id="`evaluation-${data.item.id+3}`" v-b-tooltip.click.top="data.item.evaluation">
              {{ data.item.evaluation.length > 50 ? data.item.evaluation.substr(0, 49) + '...' : data.item.evaluation }}
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import { DATE_NUMBER_SLASH, DDMMYYYY, YEAR_MONTH_DAY } from '@/libs/filterDate'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  today,
  last2,
  last7,
  firstDateOfMonth,
} from '@/store/helpers'
import {
  tableTeamLead,
  dateRange,
  locale,
  ranges,
} from '../config'

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableTeamLead,
      idItem: 0,
      dateRange,
      locale,
      ranges,
      alertError,
      DATE_NUMBER_SLASH,
      DDMMYYYY,
      today,
      last2,
      last7,
      firstDateOfMonth,
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if ((table.scrollTop >= table.scrollHeight - table.offsetHeight - 5) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&type=team_lead&keyword=${this.keyword}&start_date=${this.dateRange.startDate ? YEAR_MONTH_DAY(this.dateRange.startDate) : ''}&end_date=${this.dateRange.endDate ? YEAR_MONTH_DAY(this.dateRange.endDate) : ''}`
      const url = `v1/evaluations?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&type=team_lead&keyword=${this.keyword}&start_date=${this.dateRange.startDate ? YEAR_MONTH_DAY(this.dateRange.startDate) : ''}&end_date=${this.dateRange.endDate ? YEAR_MONTH_DAY(this.dateRange.endDate) : ''}`
        const url = `v1/evaluations?${params}`

        await komtimAxiosIns.get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    resetFilter() {
      this.filterSelected = []
      this.getListData()
    },
  },

}
</script>

<style lang="scss">
.evaluation:hover {
  color: #F95031;
  cursor: pointer;
}
.evaluation {
  width: fit-content;
}
.container-date > div, .container-date > div.vue-daterange-picker > div.form-control.reportrange-text, .form-control, .reportrange-text {
  height: 100% !important;
}
</style>
